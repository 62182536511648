import { API , Cache} from "aws-amplify";
import config from "../config/";
import {
    SET_RESOURCES,
    SET_ACTIVE_RESOURCE,
    LOADING_RESOURCES,
    SET_SKILLS,
    SET_SKILLS_VIDEOS,
    SET_NO_VIDEO_MESSAGE,
    HIDE_VIDEO_MESSAGE,
    UPDATE_OCCUPATION_SKILL,
    SET_ONLINE_COURSES,
    LOADING_ONLINE_COURSES,
    SET_HELP_VIDEOS
} from "./types";

export const viewedResource = (resource) => async (dispatch) => {
    try {
    //   // NO Response implemented yet
      await API.post(config.API_NAME, "/jobitems/resource/viewed", {
        body: {
          resourceId: resource.id,
          title: resource.title,
          link: resource.link
        },
      });

    } catch (error) {
      console.log(error);
      // TODO
    }
};


export const addResourceSkill = (skill, job) => async (dispatch, getState) => {
    try {
        //   // NO Response implemented yet
          let occ = await API.post(config.API_NAME, "/users/addResourceSkill", {
            body: {
              skill: skill
            },
          });
          
          if (occ.data && occ.data.Attributes && occ.data.Attributes.occupationSkills){
            dispatch({ type: SET_SKILLS, skill:skill, occupationSkills: occ.data.Attributes.occupationSkills });
          }
        } catch (error) {
          console.log(error);
          // TODO
        }
    // TODO
    dispatch({type: UPDATE_OCCUPATION_SKILL, job: job, skillName: skill})

    Cache.removeItem("jobCategories");
    Cache.removeItem("interestStyles");
    Cache.removeItem("jobs_nearme");

}
export const setActiveVideo = (resource) => async (dispatch) => {
    dispatch({type: SET_ACTIVE_RESOURCE, "resource": resource})
};

export const hideVideoMessage = (resource) => async (dispatch) => {
    dispatch({type: HIDE_VIDEO_MESSAGE})
}

export const getSkillVideos = (skillName) => async (dispatch, getState) => {
    
    let {data} = await API.post(config.API_NAME, "/data/skills/videos", {
        body: {
            "skill": skillName.toLowerCase()
        },
    });

    
    if(data.video_suggestions && data.video_suggestions.length > 0){
        dispatch({type: SET_SKILLS_VIDEOS,"videos": data, "skillName": skillName})
    } else {
        dispatch({type: SET_NO_VIDEO_MESSAGE, "videos": [], "skillName": skillName})
    }
}





export const getOnlineCourses = () => async (dispatch, getState) => {
    try {

        const {
            jobs: {
                rawJobCategories:{
                    desired_occupations,
                    interest_occupations,
                    past_occupations,
                    searched_occupations,
                    skills_backpack_occupations,
                    styles_occupations
                }
            } 
        } = getState();
    
        let occupations = [];
        let categoryKey = {}
        searched_occupations.forEach((index) => {
            occupations.push(index.occupation);
            categoryKey[index.occupation] = "Searched"
        })
        desired_occupations.forEach((index) => {
            occupations.push(index.occupation);
            categoryKey[index.occupation] = "Preferred"
        });
        past_occupations.forEach((index) => {
            occupations.push(index.occupation);
            categoryKey[index.occupation] = "Previous"
        })
        skills_backpack_occupations.forEach((index) => {
            occupations.push(index.occupation);
            categoryKey[index.occupation] = "Skills"
        });
        interest_occupations.forEach((index) => {
            occupations.push(index.occupation);
            categoryKey[index.occupation] = "Interest"
        })        
        styles_occupations.forEach((index) => {
            occupations.push(index.occupation);
            categoryKey[index.occupation] = "Styles"
        });
    
    dispatch({type: LOADING_ONLINE_COURSES, loading: true});

    let {data} = await API.post(config.API_NAME, "/data/occupations/mooc", {
        body: {
            "occupations": occupations
        },
    });

    dispatch({type: SET_ONLINE_COURSES, data: data, orderDict:occupations, categoryKey: categoryKey});
    dispatch({type: LOADING_ONLINE_COURSES, loading: false});


    } catch (error) {
        console.log(error);
        // TODO
    }

    
}

export const getVideos = () => async (dispatch, getState) => {
    try {
        
    const {
        jobs: {
            rawJobCategories:{
                desired_occupations,
                interest_occupations,
                past_occupations,
                searched_occupations,
                skills_backpack_occupations,
                styles_occupations
            }
        } 
    } = getState();

    let occupations = [];
    let categoryKey = {}
    searched_occupations.forEach((index) => {
        occupations.push(index.occupation);
        categoryKey[index.occupation] = "Searched"
    })
    desired_occupations.forEach((index) => {
        occupations.push(index.occupation);
        categoryKey[index.occupation] = "Preferred"
    });
    past_occupations.forEach((index) => {
        occupations.push(index.occupation);
        categoryKey[index.occupation] = "Previous"
    })
    skills_backpack_occupations.forEach((index) => {
        occupations.push(index.occupation);
        categoryKey[index.occupation] = "Skills"
    });
    interest_occupations.forEach((index) => {
        occupations.push(index.occupation);
        categoryKey[index.occupation] = "Interest"
    })        
    styles_occupations.forEach((index) => {
        occupations.push(index.occupation);
        categoryKey[index.occupation] = "Styles"
    });

    dispatch({type: LOADING_RESOURCES, loading: true});

    let {data} = await API.post(config.API_NAME, "/data/occupations/videos", {
        body: {
            "occupations": occupations
        },
    });

    dispatch({type: SET_RESOURCES, data: data, orderDict:occupations, categoryKey: categoryKey});
    dispatch({type: LOADING_RESOURCES, loading: false});


    } catch (error) {
        console.log(error);
        // TODO
    }

    
}

const unAuthenticatedVideos = {
    "UnAuth": {
        "paths": ["/login", "/signup", "/forgot"],
        "videos": [
            {
                "id": "3pELYBz2wm1fzam5uwfkEG",
                "title": "Login"
            },
            {
                "id": "95i2ZqodvxAVQkmNpQo9AD",
                "title": "Sign up"
            },
            {
                "id": "u1rYcgab18ZTtTj6ZzJVrx",
                "title": "Forgot Password"
            },
            {
                "id": "xfCXC2pYpamcFdXL8qthvE",
                "title": "Invite"
            }
        ]
    }
}

export const getHelpVideos = (path) => async (dispatch, getState) => {
    const {
        auth:{authenticated = false}, 
        user:{rawdata:{internalMobilityProvider}={}},
        resources: {authenticatedVideos}
    } = getState();
    if(authenticated){
        let videos = authenticatedVideos ? authenticatedVideos : []
        if(!authenticatedVideos){
            const data = await API.get(config.API_NAME, `/helpvideo`)
            if(data.success){
                videos = data.data
                dispatch({ type: SET_HELP_VIDEOS, data: data.data });
            }
        }
        const pathData = Object.values(videos).find((value) => value.paths.includes(path))
        if(pathData){
            return {...pathData, videos: pathData.videos.filter((item) => !item.role || (item.role && ((item.role === "candidiate" && !internalMobilityProvider) || (item.role === "employee" && internalMobilityProvider))))}                
        }
    }
    else{
        return Object.values(unAuthenticatedVideos).find((value) => value.paths.includes(path))
    }
    
}