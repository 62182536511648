// Actions related to authentication
export const AUTH_USER = "AUTH_USER";
export const AUTH_MFA = "AUTH_MFA";
export const AUTH_NEW_PASSWORD_REQUIRED = "AUTH_NEW_PASSWORD_REQUIRED";
export const UNAUTH_USER = "UNAUTH_USER";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_CONFIRM = "REGISTER_USER_CONFIRM";
export const REGISTER_MFA = "REGISTER_MFA";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_CONFIRM = "FORGOT_PASSWORD_CONFIRM";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_ERROR_CLEAR = "AUTH_ERROR_CLEAR";
export const CODE_RESEND_SUCCESS = "CODE_RESEND_SUCCESS";
export const AUTH_CONFIRM_SUCCESS = "AUTH_CONFIRM_SUCCESS";
export const ADD_TO_VIEWED_LIST = "ADD_TO_VIEWED_LIST";

export const RESET_APP = "RESET_APP";
//USER RELATED
export const FETCH_USER = "user/FETCH_USER";
export const FETCH_USER_PROFILE_URL = "user/FETCH_USER_PROFILE_URL";
export const USER_CLICK_PROFILE_EDIT = "user/USER_CLICK_PROFILE_EDIT";
export const USER_PROFILE_EDIT_DONE = "user/USER_PROFILE_EDIT_DONE";
export const USER_CLICK_HELLO = "user/CLICK_HELLO";
export const OPEN_UPDATE_SKILLS = "user/OPEN_UPDATE_SKILLS";
export const CLOSE_UPDATE_SKILLS = "user/CLOSE_UPDATE_SKILLS";
export const MARK_SKILLS_UPDATED = "user/MARK_SKILLS_UPDATED";
export const ADD_NEW_SKILL = "user/ADD_NEW_SKILL";
export const SET_NOTIFICATION = "user/SET_NOTIFICATION";
export const SET_NOTIFICATION_AMOUNT = "user/SET_NOTIFICATION_AMOUNT";
export const SET_SKILLS = "user/SET_SKILLS";
export const SET_SKILLS_VIDEOS = "user/SET_SKILLS_VIDEOS";
export const SET_USER_PERMISSIONS = "user/SET_USER_PERMISSIONS";
export const UPDATE_DEMOGRAPHICS = "user/UPDATE_DEMOGRAPHICS";
export const UPDATE_DEMOGRAPHICS_QUESTIONS = "user/UPDATE_DEMOGRAPHICS_QUESTIONS";
export const OPEN_DEMOGRAPHICS = "user/OPEN_DEMOGRAPHICS";
export const FETCH_INTERNAL_MOBILITY_ROLES = "user/FETCH_INTERNAL_MOBILITY_ROLES";
//JOBS

export const LOADED_CONTENT_DASHBOARD = "jobs/LOADED_CONTENT_DASHBOARD";
export const FETCH_OCCUPATION_JOBS = "jobs/OCCUPATION_JOBS";
export const FETCH_JOB_CATEGORIES = "jobs/JOB_CATEGORIES";
export const FETCH_STYLES_JOBS = "jobs/STYLES_JOBS";
export const FETCH_BACKPACK_JOBS = "jobs/BACKPACK_JOBS";
export const FETCH_DESIRED_JOBS = "jobs/DESIRED_JOBS";
export const FETCH_FAVOURITED_JOBS = "jobs/FAVOURITED_JOBS";
export const SET_FAVOURITE_JOB = "jobs/SET_FAVOURITE_JOB";
export const FETCH_INTERESTS_JOBS = "jobs/INTERESTS_JOBS";
export const SET_SELECTED_JOB = "jobs/SET_SELECTED_JOB";
export const FETCH_APPLIED_JOBS = "jobs/APPLIED_JOBS";
export const MARK_ITEM_READ = "jobs/MARK_ITEM_READ";
export const APPLY_FOR_A_JOB = "jobs/APPLY_FOR_A_JOB";
export const APPLY_FOR_A_JOB_UPDATE = "jobs/APPLY_FOR_A_JOB_UPDATE";
export const RESET_JOBS = "jobs/RESET_JOBS";
export const FETCH_SKILLS_LIST = "jobs/FETCH_SKILLS_LIST";
export const FETCH_QUALIFICATIONS = "jobs/FETCH_QUALIFICATIONS";
export const FETCH_ALL_JOBS = "jobs/FETCH_ALL_JOBS";
export const FETCH_ALL_JOBS_COMPLETE = "jobs/FETCH_ALL_JOBS_COMPLETE";
export const FETCH_INDIVIDUAL_JOBS = "jobs/FETCH_INDIVIDUAL_JOBS";
export const FETCH_NEARME_JOBS = "jobs/FETCH_NEARME_JOBS";
export const JOBS_LOAD = "jobs/LOAD";
export const ADD_FILTER = "jobs/ADD_FILTER";
export const OCC_FILTER = "jobs/OCC_FILTER";
export const JOBS_FILTER = "jobs/JOBS_FILTER";
export const LEVEL_FILTER = "jobs/LEVEL_FILTER";
export const FETCH_CENTRES = "jobs/FETCH_CENTRES";
export const TOGGLE_LOADING = "jobs/TOGGLE_LOADING";
export const SET_RESOURCES  = "jobs/SET_RESOURCES";
export const SET_ONLINE_COURSES = "jobs/SET_ONLINE_COURSES";
export const SET_ACTIVE_RESOURCE = "jobs/SET_ACTIVE_RESOURCE";
export const LOADING_RESOURCES = "jobs/LOADING_RESOURCES";
export const LOADING_ONLINE_COURSES = "jobs/LOADING_ONLINE_COURSES";
export const UPDATE_OCCUPATION_SKILL = "jobs/UPDATE_OCCUPATION_SKILL";
export const UPDATE_FULL_DESCRIPTION = "jobs/UPDATE_FULL_DESCRIPTION";
export const OPTIONAL_SKILL_CHECK = "jobs/OPTIONAL_SKILL_CHECK";
export const UPDATE_JOB_CATEGORIES = "jobs/UPDATE_JOB_CATEGORIES";
export const UPDATE_LOAD_MORE_COUNT = "jobs/UPDATE_LOAD_MORE_COUNT";
export const BLACKLIST_OCC = "jobs/BLACKLIST_OCC";
export const SET_BLACKLIST = "jobs/SET_BLACKLIST";
export const SET_APPLICATIONS_NOTIFICATION = "jobs/SET_APPLICATIONS_NOTIFICATION";

//resources
export const SET_NO_VIDEO_MESSAGE = "resources/SET_NO_VIDEO_MESSAGE";
export const HIDE_VIDEO_MESSAGE = "resources/HIDE_VIDEO_MESSAGE";
export const SET_HELP_VIDEOS = "resources/SET_HELP_VIDEOS";

//CHAT
export const SEND_CHAT_MESSAGE = "chat/SEND_MESSAGE";
export const RECEIVE_BOT_MESSAGE = "chat/RECEIVE_BOT_MESSAGE";
export const RECEIVE_BOT_STATE = "chat/RECEIVE_BOT_STATE";
export const RECEIVE_BOT_DONE = "chat/RECEIVE_BOT_DONE";
export const CHAT_WELL_DONE = "chat/WELL_DONE";
export const RESET_MESSAGES = "chat/RESET_MESSAGES";
export const UPDATE_CHAT_SKILLS = "chat/UPDATE_CHAT_SKILLS";

// MISSIONS / INTERVENTIONS
export const FETCH_MISSIONS = "interventions/FETCH_MISSIONS";
export const START_MISSION = "interventions/START_MISSION";
export const FETCH_INTERVENTIONS = "interventions/FETCH_INTERVENTIONS";
export const START_INTERVENTION = "interventions/START_INTERVENTION";
export const FETCH_INTERVENTIONS_MISSIONS =
  "interventions/FETCH_INTERVENTIONS_MISSIONS";

//REWARDS
export const FETCH_COINS = "rewards/FETCH_COINS";
export const RESET_COINS = "rewards/RESET_COINS";
export const RESET_MAX_COINS = "rewards/RESET_MAX_COINS";
export const PURCHASE_COMPLETE = "rewards/PURCHASE_COMPLETE";
export const PURCHASE_COMPLETE_CLOSE = "rewards/PURCHASE_COMPLETE_CLOSE";
export const RESET_COIN_DEFAULT = "rewards/RESET_COIN_DEFAULT";


//applicationAssistant
export const STORE_CV_DATA = "applicationAssistant/STORE_CV_DATA";
export const HIDE_AA_HELP_TEXT = "user/HIDE_AA_HELP_TEXT";
export const STORE_OCC_LIST_OPTION = "applicationAssistant/STORE_OCC_LIST_OPTION"
export const CREATE_JOB_CV = "applicationAssistant/CREATE_JOB_CV"
export const FETCH_ALL_CVS = "applicationAssistant/FETCH_ALL_CVS"
export const REMOVE_TEMPLATE = "applicationAssistant/REMOVE_TEMPLATE"
export const CLEAR_NEW_TEMPLATE_PROMPT = "applicationAssistant/CLEAR_NEW_TEMPLATE_PROMPT"
export const UPDATE_EXPERIENCE_PROMPTS = "applicationAssistant/UPDATE_EXPERIENCE_PROMPTS"