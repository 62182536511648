import React, {useState, useEffect} from "react";
import {  useLocation} from "react-router-dom";
import {makeStyles, Paper, Avatar, Chip, Grid, Typography, IconButton, Divider, Button, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as AvatarIcon } from "../Chat/GiftedChat/chatavatar.svg";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    anchorSmall: {
        zIndex: 99999,
        position: 'fixed',
        bottom: "0px",
        right: "0px",
        margin: "10px",
        backgroundColor:"#fff",
        padding:"10px",
    },
    anchor :{
        zIndex: 99999,
        position: 'fixed',
        bottom: "0px",
        right: "0px",
        margin: "10px",
        backgroundColor:"#fff",
        padding:"10px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "100%",
            margin: "0px",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "35%",
        }
    },
    header:{
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
    },
    avatar:{
        backgroundColor:"#fff"
    },
    text:{
        paddingBlock:"10px"
    }
}));

export default function Help({isAuthenticated, getHelpVideos}){
    const classes = useStyles();
    const location = useLocation();
    const [open, isOpen] = useState(false)
    const [expanded, setExpanded] = React.useState(0);
    const [currentPath, setCurrentPath] = useState(undefined);
    const [helpObject, setHelpObject] = React.useState(false);

    useEffect(() => {
        async function pathChange(){
            if(location.pathname !== currentPath || !helpObject){
                setCurrentPath(location.pathname);
                setHelpObject(await getHelpVideos(location.pathname))
            }
        }
        pathChange()
    }, [location, isAuthenticated]);

    if(helpObject && helpObject.videos){
        if(open){
            return (
                <Paper 
                    elevation={3}
                    className={classes.anchor}
                >
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{width: "100%"}}>
                        <Grid item xs>
                            <Typography style={{display:"flex", alignItems:"center"}} variant="h6">    
                                <Avatar className={classes.avatar}>
                                    <AvatarIcon />
                                </Avatar>
                                Need Help?
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => isOpen(!open)}>
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Typography className={classes.text}>
                        Some of these videos might help you get up to speed with this page
                    </Typography>
                        {helpObject.videos.map((item, index) => {
                            const videoURL = "https://embed.app.guidde.com/playbooks/" + item.id
                            return (
                                <Accordion expanded={expanded === index} onChange={() => setExpanded(index)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        {item.title}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <iframe width="100%" height="250px" src={videoURL} title={item.title} frameborder="0" referrerpolicy="unsafe-url" allowfullscreen="true" allow="clipboard-write" sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"/>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                </Paper>
            )
        }
        else{
            return (
                <Chip
                    className={classes.anchorSmall}
                    onClick={() => isOpen(!open)}
                    avatar={<Avatar><AvatarIcon /></Avatar>}
                    label="Need Help?"
                    variant="outlined"
                    color="primary"
                />
            )
        }
    }
    else{
        return <></>
    }
}