import {
    SET_SKILLS_VIDEOS,
    SET_NO_VIDEO_MESSAGE,
    HIDE_VIDEO_MESSAGE,
    SET_HELP_VIDEOS
} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case SET_SKILLS_VIDEOS: {
            if (state.skill_videos){
                state.skill_videos[action.skillName] = action.videos;
                return {
                    ...state,
                    skill_videos: state.skill_videos
                }
            } else {
                let video = action.videos;
                let obj = {};
                obj[action.skillName] = video;
            
                return {
                    ...state,
                    skill_videos: obj
                }
            }
        }
        case SET_NO_VIDEO_MESSAGE: {
            let message = `No resources has been found for ${action.skillName}`;
            return {
                ...state,
                skill_notification: message
            }
        }
        case HIDE_VIDEO_MESSAGE: {
            return {
                ...state,
                skill_notification: false
            }
        }
        case SET_HELP_VIDEOS:{
            return {
                ...state,
                authenticatedVideos: action.data
            }
        }
        default: return state;
    }
}