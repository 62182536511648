import React, { Component, lazy, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Cache, Analytics } from "aws-amplify";
import { fetchUser } from "../../actions/user_actions";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";

import MuiAlert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Spring, config } from "react-spring/renderprops";
import {
  fetchCoins,
  resetCoins,
  resetMaxCoins,
  checkOrders,
  resetCoinDefault,
  closePurchaseDialogue
} from "../../actions/reward_actions";

import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import Navigation from "./Navigaton";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as ROUTES from "../../constants/routes";
import Demographics from "./Demographics";
import Hello from "./Hello";
import WellDone from "./WellDone";
import PurchaseComplete from "./PurchaseComplete"
import { ReactComponent as SmallCoin } from "../Common/Icons/smallCoin.svg";
import { ReactComponent as RewardShield } from "../Profile/icons/rewardShield.svg";

const Profile = lazy(() => import("../Profile"));
const ProfileSettings = lazy(() => import("../Profile/ProfileSettings"));
const Chat = lazy(() => import("../Chat"));
const Default = lazy(() => import("./Default"));
const Jobs = lazy(() => import("../Jobs"));
const JobDetails = lazy(() => import("../Jobs/JobDetails"));
const Missions = lazy(() => import("../Missions"));
const ResourceDetails = lazy(() => import("../Resources/ResourceDetails"));
const Privacy = lazy(() => import("../Privacy"));
const Faq = lazy(() => import("../Faq"));
const OrgProfile = lazy(() => import("../OrgProfile"));
const ApplicationAssistant = lazy(() => import("../ApplicationAssistant"));

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  layout: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  nav: {
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
    },
  },
  loading: {
    margin: "auto",
  },
  loadingmain: {
    width: "100%",
  },
  coinText: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    color: "#FFFFFF",
    fontFamily: [
      "Spectral",
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  coinIcon: {
    marginLeft: 8,
    marginRight: 8,
    width: 24,
    height: 24,
    marginBottom: -6,
  },
  rewardIcon: {
    marginLeft: 8,
    marginRight: 8,
    width: 48,
    height: 48,
    marginBottom: -6,
  },
  orangeBG: {
    backgroundColor : "#FF6A14"

  }
});
const NotFoundRedirect = () => <Redirect to="/app" />;

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const mapObj = (f) => (obj) =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
const toArrayOfStrings = (value) => [`${value}`];
/* eslint-disable */
const mapToArrayOfStrings = mapObj(toArrayOfStrings);
/* eslint-enable */

class Main extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  }

  state = {
    chatActive: false,
    isFocused: true,
    shouldShowCoins: false,
    showCoins: false,
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.resetCoins();
  };

  handleClose() {}

  async componentDidMount() {
    const { history } = this.props;
    document
      .querySelector(":root")
      .style.setProperty("--vh", window.innerHeight / 100 + "px");
    //SETUP ANALYTICS

    Analytics.autoTrack("pageView", { enable: true, type: "SPA" });

    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("blur", this.handleBlur);

    history.listen((newLocation, action) => {
      // location is an object like window.location
      Cache.setItem("lastpath", newLocation.pathname);
      Cache.setItem("previouspath", this.props.location.pathname);

      if (action === "PUSH") {
        if (
          newLocation.pathname !== this.currentPathname ||
          newLocation.search !== this.currentSearch
        ) {
          // Save new location
          this.lastpath = this.props.location.pathname;  //new item for getting previous path
          this.currentPathname = newLocation.pathname;
          this.currentSearch = newLocation.search;

          // Clone location object and push it to history
          if (newLocation.pathname === "/app/chat"){
            history.push({
              pathname: newLocation.pathname,
              search: newLocation.search,
              lastpath : this.lastpath
            });
          }
        }
      } else if (action === "POP") {

        let lastPath = Cache.getItem("lastpath", newLocation.pathname);
        if (lastPath === "/app/chat"){
          history.go(1);
        }
        // use browser go back method.
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });

    window.addEventListener("resize", () => {
      //chat height trick
      document
        .querySelector(":root")
        .style.setProperty("--vh", window.innerHeight / 100 + "px");
    });

    await this.props.fetchUser();
    console.log("MAIN MOUNT COINS");
    await this.props.fetchCoins();
    await this.props.checkOrders();
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
  }

  handleFocus = () => {
    var that = this;

    setTimeout(() => {
      that.setState({
        isFocused: true,
        showCoins: this.state.shouldShowCoins,
      });
    }, 300);
  };
  handleBlur = () => {
    this.setState({
      isFocused: false,
    });
  };

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):

    if (
      this.props.user &&
      prevProps.user &&
      this.props.user.showChat !== prevProps.user.showChat &&
      this.props.user.showChat === true
    ) {
      // await this.props.fetchUser();
      //console.log("SLEEP");
      // await this.sleep(2000);
      //console.log("SLEEP END");
      //

      if (this.props.history.location.pathname.indexOf(ROUTES.CHAT) < 0) {
        this.props.history.push(ROUTES.CHAT);
      }
      this.setState({
        chatActive: true,
      });
    }

    if (
      this.props.user &&
      prevProps.user &&
      this.props.user.showChat !== prevProps.user.showChat &&
      this.props.user.showChat === false
    ) {
      if (this.props.history.location.pathname.indexOf(ROUTES.CHAT) >= 0) {
        this.props.history.push(ROUTES.APP);
      }
      this.setState({
        chatActive: false,
      });
    }

    if (
      this.props.rewards &&
      prevProps.rewards &&
      this.props.rewards.newCoins !== 0 &&
      prevProps.rewards.newCoins === 0
    ) {
      this.setState({
        shouldShowCoins: true,
        showCoins: this.state.isFocused,
      });
    }

    if (
      this.props.rewards &&
      prevProps.rewards &&
      this.props.rewards.newCoins === 0 &&
      prevProps.rewards.newCoins !== 0
    ) {
      this.setState({
        showCoins: false,
        shouldShowCoins: false,
        isLevelUp: false,
      });
    }

    if (
      this.props.rewards &&
      prevProps.rewards &&
      this.props.rewards.level !== prevProps.rewards.level &&
      prevProps.rewards.level >= 1
    ) {
      this.setState({
        isLevelUp: true,
      });
    }
  }

  render() {
    const {
      classes,
      user: { email, orgDetails = {}},
      rewards: { newCoins = 0, level = 1, dailyLimitReachedNotification, purchaseComplete, stripeOrders, showOrgDefault },
    } = this.props;
    const { name } = orgDetails;
    // console.log(this.props)

    let defaultRoute;
    let removedCoins = (newCoins < 0) ? Math.abs(newCoins) : false

    if (this.state.chatActive) {
      defaultRoute = Default;
    } else {
      defaultRoute = Jobs;
      //this.props.history.push(ROUTES.JOBS);
    }

    return (
      <div className={classes.root}>
        {!email && (
          <div style={{ display: "flex" }}>
            <LinearProgress className={classes.loadingmain} />
          </div>
        )}
        {email && (
          <>
            <Hello />
            <Demographics />
            <WellDone />

            <Navigation className={classes.nav} />
            <div className={classes.layout}>
              <Suspense
                fallback={
                  <div style={{ display: "flex" }}>
                    <CircularProgress className={classes.loading} size={28} />
                  </div>
                }
              >
                <Switch>
                  <Route exact path={ROUTES.JOBS} component={Jobs} />
                  <Route
                    exact
                    path={ROUTES.JOB_DETAILS}
                    component={JobDetails}
                  />
                  <Route exact path={ROUTES.RESOURCE_DETAILS} component={ResourceDetails} />
                  <Route exact path={ROUTES.PROFILE} component={Profile} />
                  <Route exact path={ROUTES.PROFILE_SETTINGS} component={ProfileSettings} />
                  <Route exact path={ROUTES.ORGANISATION_PROFILE} component={OrgProfile} />
                  <Route exact path={ROUTES.APP} component={defaultRoute} />
                  <Route exact path={ROUTES.CHAT} component={Chat} />
                  <Route exact path={ROUTES.MISSIONS} component={Missions} />
                  <Route exact path={ROUTES.PRIVACY} component={Privacy} />
                  <Route exact path={ROUTES.FAQ} component={Faq} />
                  <Route exact path={ROUTES.APPLICATION_ASSISTANT} component={ApplicationAssistant} />
                  <Route component={NotFoundRedirect} />
                </Switch>
              </Suspense>
            </div>
              
            <Snackbar
              open={removedCoins && this.state.showCoins && !purchaseComplete}
              onClose={this.handleClose}
              autoHideDuration={5000}
              key={"spent"}
              anchorOrigin={{ vertical: "top", horizontal: "right"}}
            >
              <Alert
                severity="success"
                icon={
                  <Typography variant="body1" className={classes.coinText}>
                    <Spring
                      from={{ transform: "scale(-1, 1)" }}
                      to={{ transform: "scale(1, 1)" }}
                      config={config.wobbly}
                      delay={1200}
                    >
                      {(props) => (
                        <SmallCoin style={props} className={classes.coinIcon} />
                      )}
                    </Spring>
                  </Typography>
                }
              >
                 {"Spent "+ removedCoins +" coins." }
              </Alert>
            </Snackbar>

            <Snackbar
              open={this.state.showCoins && this.state.isLevelUp && !removedCoins && !purchaseComplete}
              onClose={this.handleClose}
              autoHideDuration={5000}
              key={"levelup"}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                className={classes.orangeBG}
                severity="warning"
                icon={
                  <Typography variant="body1" className={classes.coinText}>
                    <Spring
                      from={{ transform: "scale(-1, 1)" }}
                      to={{ transform: "scale(1, 1)" }}
                      config={config.wobbly}
                      delay={1200}
                    >
                      {(props) => (
                        <RewardShield
                          style={props}
                          className={classes.rewardIcon}
                        />
                      )}
                    </Spring>
                  </Typography>
                }
              >
                <AlertTitle><strong>Level Up! </strong></AlertTitle>
                 +{newCoins} points. You are on Level {level} 🏆
              </Alert>
            </Snackbar>

            <Snackbar
              open={this.state.showCoins && this.state.shouldShowCoins && !this.state.isLevelUp && !removedCoins && !purchaseComplete}
              onClose={this.handleClose}
              autoHideDuration={5000}
              key={"coin"}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                severity="success"
                icon={
                  <Typography variant="body1" className={classes.coinText}>
                    <Spring
                      from={{ transform: "scale(-1, 1)" }}
                      to={{ transform: "scale(1, 1)" }}
                      config={config.wobbly}
                      delay={1200}
                    >
                      {(props) => (
                        <SmallCoin style={props} className={classes.coinIcon} />
                      )}
                    </Spring>

                    <Spring from={{ number: 0 }} to={{ number: newCoins }}>
                      {(props) => <>+{parseInt(props.number)}</>}
                    </Spring>
                  </Typography>
                }
              >
                You just earned {newCoins} points. Keep it up!
              </Alert>
            </Snackbar>
            <Snackbar
              open={dailyLimitReachedNotification && !purchaseComplete}
              onClose={() => this.props.resetMaxCoins()}
              autoHideDuration={5000}
              key={"coin"}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                severity="error"
              >
                Maximum number of coins earned today. Earn more tomorrow
              </Alert>
            </Snackbar>
            <Snackbar
              open={showOrgDefault}
              onClose={() => this.props.resetCoinDefault() }
              autoHideDuration={5000}
              key={"spent"}
              anchorOrigin={{ vertical: "top", horizontal: "right"}}
            >
              <Alert
                severity="success"
                icon={
                  <Typography variant="body1" className={classes.coinText}>
                    <Spring
                      from={{ transform: "scale(-1, 1)" }}
                      to={{ transform: "scale(1, 1)" }}
                      config={config.wobbly}
                      delay={1200}
                    >
                      {(props) => (
                        <SmallCoin style={props} className={classes.coinIcon} />
                      )}
                    </Spring>
                  </Typography>
                }
              >
                 {"+ " + showOrgDefault + " coins added from " + name}
              </Alert>
            </Snackbar>
            <PurchaseComplete open={purchaseComplete} stripeOrders={stripeOrders} onClose={() => this.props.closePurchaseDialogue()}/>
          </>
        )}
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      { fetchUser, fetchCoins, resetCoins, resetMaxCoins, checkOrders,resetCoinDefault, closePurchaseDialogue },
      dispatch
    ),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Main)));
